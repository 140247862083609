// 
// Project styles
// 

// This file is intentionally blank
// Use this file to override SCSS within the project, or to add new SCSS
// for creating your own CSS components

.toast-custom{
    position: fixed;
    bottom: 0;
}
